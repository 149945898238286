import React, { useState, useRef, useEffect } from "react";
import "./App.css";

function App() {
	const [isDragging, setIsDragging] = useState(false);
	const [startX, setStartX] = useState(0);
	const [scrollLeft, setScrollLeft] = useState(0);
	const containerRef = useRef(null);
	const [searchText, setSearchText] = useState("");
	const [currentImageIndex, setCurrentImageIndex] = useState(0);
	const [profileList, setProfileList] = useState(() => {
		const savedProfiles = localStorage.getItem("profileList");
		return savedProfiles ? JSON.parse(savedProfiles) : [];
	});
  const [imageLoaded, setImageLoaded] = useState({});
  const [timestamp, setTimestamp] = useState(null);
	const corsProxyUrl = "https://corsproxy.io/?";
	const storyUrl =
		"https://api-ig.storiesig.info/api/story?url=https//www.instagram.com/stories/";
	const profileUrl = "https://api-ig.storiesig.info/api/userInfoByUsername/";
	const [storysList, setStorysList] = useState([]);
	const [hasStories, setHasStories] = useState(false);

	const [images, setImages] = useState([
		"https://i.pinimg.com/474x/7f/b8/98/7fb898baca826b9969752a76b038f793.jpg",
		"https://i-sam.unimedias.fr/2022/07/27/rire-avec-amis-avec-amoureux.jpeg?auto=format%2Ccompress&crop=faces&cs=tinysrgb&fit=crop&h=501&w=890",
		"https://www.touraineloirevalley.com/wp-content/uploads/2019/11/parcours_lumiere_tours_credit_adt_touraine_jc_coutand_2029-9.jpg",
	]);

	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartX(e.pageX - containerRef.current.offsetLeft);
		setScrollLeft(containerRef.current.scrollLeft);
	};

	const handleMouseLeave = () => {
		setIsDragging(false);
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	const handleMouseMove = (e) => {
		if (!isDragging) return;
		e.preventDefault();
		const x = e.pageX - containerRef.current.offsetLeft;
		const walk = (x - startX) * 2;
		containerRef.current.scrollLeft = scrollLeft - walk;
	};

	const handleProfileClick = (index) => {
		const clickedProfile = profileList[index];
		const profileStories = storysList.find(story => story.username === clickedProfile.username);
		
		if (profileStories && profileStories.stories.length > 0) {
			console.log(`Stories pour ${clickedProfile.username}:`, profileStories.stories);
			
			const newImages = profileStories.stories.map(story => 
				`${corsProxyUrl}${encodeURIComponent(story.image_versions2.candidates[0].url)}`
			);

			const newTimestamp = profileStories.stories[0].taken_at;
			setTimestamp(newTimestamp);

			console.log("Nouvelles images :", newImages);
			setImages(newImages);
			setCurrentImageIndex(0);
			setHasStories(true);
		} else {
			console.log(`Aucune story trouvée pour ${clickedProfile.username}`);
			setImages([]);
			setCurrentImageIndex(0);
			setHasStories(false);
		}
	};

	const nextImage = () => {
		setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	const prevImage = () => {
		setCurrentImageIndex(
			(prevIndex) => (prevIndex - 1 + images.length) % images.length,
		);
	};

	const handleSearch = () => {
		if (searchText.trim()) {

			const profileExists = profileList.some(profile => profile.username.toLowerCase() === searchText.trim().toLowerCase());
			
			if (profileExists) {
				alert("Ce profil est déjà dans votre liste.");
				return;
			}

			fetch(`${corsProxyUrl}${encodeURIComponent(profileUrl + searchText.trim())}`)
				.then((response) => {
					if (!response.ok) {
						throw new Error("Profil non trouvé");
					}
					return response.json();
					})
				.then((data) => {
					if (data.result.user.is_private === false) {
						const decodedUrl = decodeURIComponent(
							data.result.user.hd_profile_pic_url_info.url,
							);
						const proxiedImageUrl = `${corsProxyUrl}${decodedUrl}`;
						const newProfile = {
							username: data.result.user.username,
							profilePicUrl: proxiedImageUrl,
						};
						setProfileList((prevList) => {
							const updatedList = [...prevList, newProfile];
							localStorage.setItem("profileList", JSON.stringify(updatedList));
							return updatedList;
						});
						setSearchText("");
						fetchStoriesForProfile(newProfile);
					} else {
						alert("Ce profil est privé.");
					}
				})
				.catch((error) => {
					console.log(`${profileUrl}${searchText.trim()}`);
					alert(`Ce profil n'existe pas.`);
				});
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			handleSearch();
		}
	};

	useEffect(() => {
		const savedProfiles = localStorage.getItem("profileList");
		if (savedProfiles) {
			const parsedProfiles = JSON.parse(savedProfiles);
			Promise.all(
				parsedProfiles.map((profile) =>
					fetch(`${corsProxyUrl}${encodeURIComponent(profileUrl + profile.username)}`)
						.then((response) => response.json())
						.then((data) => {
							const decodedUrl = decodeURIComponent(
								data.result.user.hd_profile_pic_url_info.url,
							);
							const proxiedImageUrl = `${corsProxyUrl}${decodedUrl}`;
							return { ...profile, profilePicUrl: proxiedImageUrl };
						})
						.catch((error) => {
							console.error(
								`Erreur lors de la mise à jour du profil ${profile.username}:`,
								error,
							);
							return profile;
						}),
				),
			).then((updatedProfiles) => {
				setProfileList(updatedProfiles);
				
				Promise.all(
					updatedProfiles.map((profile) =>
						fetch(`${corsProxyUrl}${encodeURIComponent(storyUrl + profile.username)}`)
							.then((response) => response.json())
							.then((storyData) => ({
								username: profile.username,
								stories: storyData.result,
							}))
							.catch((error) => {
								console.error(
									`Erreur lors de la récupération des stories pour ${profile.username}:`,
									error,
								);
								return { username: profile.username, stories: [] };
							}),
					),
				).then((storiesData) => {
					setStorysList(storiesData);
					console.log("Données des stories chargées :", storiesData);
					storiesData.forEach((profileStories) => {
						console.log(`Stories pour ${profileStories.username}:`, profileStories.stories);
					});
				});
			});
		}
	}, []);

	const fetchStoriesForProfile = (profile) => {
		fetch(`${corsProxyUrl}${encodeURIComponent(storyUrl + profile.username)}`)
			.then((response) => response.json())
			.then((storyData) => {
				setStorysList((prevStorysList) => [
					...prevStorysList,
					{ username: profile.username, stories: storyData.result }
				]);
				console.log(`Stories chargées pour ${profile.username}:`, storyData.result);
			})
			.catch((error) => {
				console.error(
					`Erreur lors de la récupération des stories pour ${profile.username}:`,
					error
				);
			});
	};

	useEffect(() => {
		if (timestamp) {
			console.log("Timestamp mis à jour :", timestamp);
		}
	}, [timestamp]);

	return (
		<>
			<div className="flex h-dvh w-screen items-center justify-center bg-white dark:bg-gray-800 md:bg-slate-50 md:dark:bg-gray-900">
				<div className="flex w-full h-4/5 md:w-2/5 flex-col rounded-3xl bg-white dark:bg-gray-800 py-4 px-4">
					<div className="flex w-full justify-center items-center">
						<h1 className="text-5xl font-bold bg-gradient-to-r from-fuchsia-600 to-pink-600 bg-clip-text text-transparent font-bluevinyl">
							instalk
						</h1>
					</div>
					<div className="px-2 py-4">
						<div className="flex h-11 w-full rounded-3xl border-[2px] border-[#94A3B8]/50 dark:border-gray-600">
							<input
								type="search"
								value={searchText}
								onChange={(e) => setSearchText(e.target.value)}
								onKeyPress={handleKeyPress}
								className="ml-3 flex-1 bg-transparent outline-none placeholder:text-slate-300 dark:placeholder:text-gray-500 dark:text-white"
								placeholder="Rechercher un profil Instagram"
							/>
							<button type="button" className="w-9" onClick={handleSearch}>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									stroke="#94A3B8"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
									className="lucide lucide-search dark:stroke-gray-400"
									title="Rechercher un profil"
									aria-label="Rechercher un profil"
									aria-hidden="false"
								>
									<circle cx="11" cy="11" r="8" />
									<path d="m21 21-4.3-4.3" />
								</svg>
							</button>
						</div>
					</div>
					<div
						ref={containerRef}
						className="min-h-20 w-full px-2 overflow-x-auto scrollbar-hide cursor-grab active:cursor-grabbing flex items-center"
						id="pp-container"
						onMouseDown={handleMouseDown}
						onMouseLeave={handleMouseLeave}
						onMouseUp={handleMouseUp}
						onMouseMove={handleMouseMove}
					>
						<div className="flex gap-2">
							{profileList.map((profile, index) => (
								<div
									key={index}
									className="flex-shrink-0 h-[76px] w-[76px] rounded-full relative hover:cursor-pointer"
									onClick={() => handleProfileClick(index)}
								>
									{storysList.some(story => story.username === profile.username && story.stories.length > 0) && (
										<div className="absolute inset-0 bg-gradient-to-b from-[#d701bd] to-[#ffc025] rounded-full animate-spin-slow"></div>
									)}
									<img
										src={profile.profilePicUrl}
										alt={`Profil de ${profile.username}`}
										crossOrigin="anonymous"
										onLoad={() =>
											setImageLoaded((prev) => ({
												...prev,
												[profile.username]: true,
											}))
										}
										className={`absolute inset-[3px] rounded-full w-[70px] h-[70px] border-[2px] border-white object-cover ${
											imageLoaded[profile.username]
												? "opacity-100"
												: "opacity-0"
										} transition-opacity duration-300`}
									/>
									{!imageLoaded[profile.username] && (
										<div className="absolute inset-[3px] rounded-full bg-gray-200 flex items-center justify-center w-[70px] h-[70px]">
											<div class="loader"></div>
										</div>
									)}
									<span className="absolute bottom-[-20px] left-1/2 transform -translate-x-1/2 text-xs text-center whitespace-nowrap overflow-hidden overflow-ellipsis w-20">
										{profile.fullName}
									</span>
								</div>
							))}
						</div>
					</div>
					{hasStories && (
						<div className="flex flex-1 flex-col px-2 md:px-0" id="storys">
							<span className="px-2 py-2 text-right text-sm text-slate-400 dark:text-gray-500">
								{new Date(timestamp * 1000).toLocaleString()}
							</span>
							<div className="flex-1 px-2 md:px-0 relative">
								<img
									src={images[currentImageIndex]}
									alt={`${currentImageIndex + 1}`}
									crossOrigin="anonymous"
									className="absolute inset-0 w-full h-full rounded-3xl md:rounded-b-3xl md:rounded-t-none object-cover object-center"
								/>
								<div className="absolute top-2 right-2 bg-black/70 rounded-full w-10 h-10 flex items-center justify-center">
									<span className="text-white text-sm font-bold">{`${currentImageIndex + 1}/${images.length}`}</span>
								</div>
								<button
									onClick={prevImage}
									className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-black/50 text-white rounded-full w-10 h-10 flex items-center justify-center"
								>
									&#10094;
								</button>
								<button
									onClick={nextImage}
									className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-black/50 text-white rounded-full w-10 h-10 flex items-center justify-center"
								>
									&#10095;
								</button>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default App;
